import React from "react"
import "./changes.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"

const Changes = () => {
  return (
    <Layout>
      <SEO title="Release Notes" />
      <div className="changesWrapper">
        <h1>Release 1.2</h1>
        <p>14th June 2024</p>
        <p>
          This release is all about meeting customer needs. It’s a big one that
          includes a refreshed design and several exciting functionalities.
        </p>
        <ol>
          <li>
            Our new website showcases SHAPE’s ability to navigate organisations
            to flourish up to 10x faster
          </li>
          <li>
            Single sign on (SSO) lets employees effortlessly sign into SHAPE
          </li>
          <li>
            Enhanced report design uses modals to ensure faster navigation and
            vastly improved report viewing on mobile devices
          </li>
          <li>
            Faster Flourishing is here. Multipliers lead the way in providing
            the fastest route to improving SHAPE scores
          </li>
          <li>
            Simpler view of Explorer and Driver scores to understand what needs
            prioritising, improving and maintaining
          </li>
          <li>
            Score history for all Explorers provides a snapshot of every
            individual’s progress throughout multiple survey rounds
          </li>
          <li>
            Resurveying means everyone gets to improve their scores and track
            personal, team and company progress
          </li>
          <li>
            Transitioned by renaming Factors Outside Work Explorer to the new
            Life Outside Work Explorer
          </li>
          <li>
            Clients can use personal emails for employees with identity
            protection assurance
          </li>
          <li>
            Improvements in email communications for more effective message
            delivery
          </li>
        </ol>
        <br />
        <br />

        <h1>Release 1.1.11</h1>
        <p>30th December 2023</p>
        <p>
          We’ve focused on the little things to make the biggest difference in
          the way you understand your scores and interact with SHAPE.
        </p>
        <ol>
          <li>
            Following thorough analysis, we switched up the Personal Work Ethic
            Explorer with the new Coworker Dynamic Explorer
          </li>
          <li>
            No company email address? No problem. Introducing non-company UIDs
            to enable access for organisations wishing to use their personal
            email addresses to sign-up to SHAPE
          </li>
          <li>
            Best Practices now display the number and tags of drivers they
            relate to
          </li>
          <li>
            We refined FAQs to assist everyone in getting the guidance they need
          </li>
          <li>EAP whitelisting is now possible</li>
        </ol>
        <br />
        <br />

        <h1>Release 1.1.10</h1>
        <p>18th September 2023</p>
        <p>
          We’ve been focused on optimisation and innovation this release. From
          optimising self-onboarding, to innovating a whole new scoring system,
          we’ve uplifted the end-to-end experience of SHAPE.
        </p>
        <p>Here’s what we have for you:</p>
        <ol>
          <li>
            Updated UI in Onboarding to facilitate an even better, seamless
            experience with improved statements and guidance notes
          </li>
          <li>
            Nudge support for Managers to boost survey participation of their
            team members
          </li>
          <li>
            New and improved email messaging flow to support timely updates and
            action notifications
          </li>
          <li>
            New Psychological Health Explorer index scoring and reporting of
            additional factors we studied through the data insights
          </li>
          <li>
            Optimised anonymity reporting views for Team Managers who have
            insufficient number of team members
          </li>
          <li>
            Introduction of a groundbreaking new scoring system called ProScore
            – reporting personal, team and company level Flourishing, Striving
            or Struggling across every factor SHAPE measures
          </li>
          <li>Ability to easily switch between ProScore and Average scores</li>
          <li>
            New at-a-glance scores status for all reports and where to focus
            attention
          </li>
          <li>
            More focused reporting for individual employees, managers and
            executives without All view
          </li>
          <li>
            And following extensive UX testing and feedback studies, we have a
            new presentation of ROI metrics as Time to Gain, Team Time Advantage
            and Company Economic Advantage
          </li>
        </ol>
        <br />
        <br />
        {/* release 1.1.9 */}
        <h1>Release 1.1.9</h1>
        <p>15th May 2023</p>
        <p>
          It’s a big release this time, one of our biggest. We’ve been
          developing new functions, and major revisions to existing features to
          completely uplift them. We think we’ve achieved world-first status in
          some cases.
        </p>
        <p>In this release we have for you:</p>

        <ol>
          <li>
            All scoring has been carefully redesigned with a new colour range to
            distinguish areas of success and concern. This process expands the
            range for all scores - higher highs, lower lows - to avoid “flat”
            looking data.
          </li>
          <li>
            Your Network has been redesigned from the ground up to be simpler,
            faster and more intuitive
          </li>
          <li>
            A simpler and more flexible nomination process of Executives during
            Onboarding by the SSA
          </li>
          <li>
            New consolidated Health Age scorecard of your individual, team, and
            company's Health Age
          </li>
          <li>
            The reporting of the Depression, Anxiety and Stress Scales (DASS)
            has been consolidated to a single scorecard showing individual, team
            and company data
          </li>
          <li>
            Signposting employees with moderate or higher psychological health
            risk to their corporate EAP or specialist healthcare provider
          </li>
          <li>
            Question Level Breakdown now has a more interactive, friendly UI for
            easy viewing of scores
          </li>
          <li>
            All comparison selection cards have been simplified to select Teams
            for viewing analysis
          </li>
          <li>
            The arrangement and selection of Best Practices articles has been
            optimised
          </li>
          <li>
            “Free text” survey questions remind of the importance of privacy
            safeguarding
          </li>
          <li>
            A new optional question about ethnicity to support company level
            diversity and inclusion reporting
          </li>
          <li>
            Finally, a helpful NEW tag notifies of the new functions throughout
            the Report
          </li>
        </ol>

        <br />
        <br />

        {/* release 1.1.8 */}
        <h1>Release 1.1.8</h1>
        <p>30th December 2022</p>
        <p>
          We took a big break to develop some incredibly complex new functions.
          Some simple ones too!
        </p>
        <p>In this release we have for you:</p>

        <ol>
          <li>
            Introducing Focus Areas in Personal, Manager and Executive Reports
            to support priority actions
          </li>
          <li>
            A new feature for Executives called Heatmap. This advanced tool
            helps dashboard and summarise full audience scores, and dig deeper
            by querying the data through multi-filters.
          </li>
          <li>
            Demographic Insights provides insights into demographic audience
            scores that can be further examined through Heatmap.
          </li>
          <li>
            Scale SHAPE from an existing small-scale audience to return and new
            audiences
          </li>
          <li>
            A powerful new ability to define survey audiences easily through the
            new Data Import Function
          </li>
          <li>
            The Work from Home Report now reports the proportions of staff who
            are struggling through to balanced
          </li>
          <li>Complete the survey from the comfort of your mobile devices</li>
        </ol>
        <p>
          A whole bunch of other refinements and performance improvements have
          been made throughout the year. SHAPE feels faster and more responsive.
        </p>
        <br />
        <br />

        {/* release 1.1.7 */}
        <h1>Release 1.1.7</h1>
        <p>5th July 2022</p>
        <p>
          Thank you to all users for your ongoing feedback. We've been busy over
          the past few months, perfecting the product through multiple user
          testing sessions & adding new components to really bring the product
          to life. In this release we have:
        </p>

        <ol>
          <li>
            Upgraded Value of Benefit to the instantly recognisable term "ROI"
            for Personal, Manager and Executive Reports
          </li>
          <li>
            You can now add your logo when onboarding your company to the SHAPE
            platform
          </li>
          <li>
            You can fast-track from results to the Best Practices articles for
            guidance on how to improve personal, team and company scores
          </li>
          <li>
            Some enhancements to make Your Network even slicker in experience
          </li>
        </ol>
        <br />
        <br />

        {/* release 1.1.6 */}
        <h1>Release 1.1.6</h1>
        <p>26th April 2022</p>
        <p>
          Thanks to everyone who's using SHAPE & providing feedback. Over the
          past several weeks, we’ve been working hard to launch some new
          enhancements as well as refining the existing experience. In this
          release we have:
        </p>

        <ol>
          <li>
            Removal of some questions in Explorers to optimise the experience
          </li>
          <li>New notification on 30 Minute session timeout</li>
          <li>Fixes applied to minor bugs across the SHAPE Survey & Report</li>
          <li>
            Launch of the new Best Practices articles and resources, coupled
            with personalised scoring
          </li>
          <li>Updated screens for SSA when completing Client Onboarding</li>
        </ol>
        <br />
        <br />

        {/* release 1.1.5 */}
        <h1>Release 1.1.5</h1>
        <p>21st February 2022</p>
        <p>
          Over the past few weeks we have been refining the product, all thanks
          to your valuable feedback. In this release we have:
        </p>

        <ol>
          <li>A new simpler Your Network experience</li>
          <li>Optimisations applied to the SHAPE Survey time out session</li>
          <li>Updated graphics within the SHAPE Report</li>
          <li>Updated information card for VOB within the SHAPE Report</li>
          <li>Minor refinements applied to the SHAPE Survey</li>
          <li>One-tap access to resources via the Survey Plan</li>
        </ol>
        <p>We're continuing to refine and revise as we progress.</p>
        <br />
        <br />

        {/* release 1.1.4 */}
        <h1>Release 1.1.4</h1>
        <p>31st December 2021</p>
        <p>
          We've been busy working on bringing new features to life while
          optimising key modules to perform more seamlessly, thanks to your
          feedback. Here's what we've got for you in this release:
        </p>

        <ol>
          <li>
            Introduced the all new SHAPE Kit 'Survey Plan' for SSAs and Managers
            to understand their roles in the SHAPE journey
          </li>
          <li>
            Team and Company Scores can be suppressed at question-level for
            Individuals and Managers in the SHAPE Report
          </li>
          <li>
            The SSA can now select the most appropriate job title for the head
            of their organisation taking the survey, such as the CEO or SVP
          </li>
          <li>
            We can also support multiple SHAPE surveys running within the same
            company
          </li>
        </ol>
        <p>Keep the feedback coming... we'll keep improving and expanding</p>
        <br />
        <br />

        {/* release 1.1.3 */}
        <h1>Release 1.1.3</h1>
        <p>17th December 2021</p>
        <p>
          Thanks to all of you who used SHAPE & provided critical feedback. With
          your help; in this release we have:
        </p>

        <ol>
          <li>
            Allowed for 'Factsheets' within the SHAPE Report to be previewed
            before being downloaded, as opposed to just being downloaded
            previously
          </li>
          <li>
            Minor bugs & fixes applied to the information cards in the SHAPE
            Report
          </li>
          <li>
            Minor bugs & fixes applied to the eNPS within the SHAPE Report
          </li>
        </ol>
        <br />
        <br />

        {/* release 1.1.2 */}
        <h1>Release 1.1.2</h1>
        <p>3rd December 2021</p>
        <p>
          We value everyone who has been supporting SHAPE. In this release we
          have:
        </p>

        <ol>
          <li>
            Updated text for the SSA post-onboarding & SSA start survey screens
          </li>
          <li>Minor bugs & fixes regarding SHAPE Report</li>
        </ol>
        <br />
        <br />
        {/* release 1.1.1 */}
        <h1>Release 1.1.1</h1>
        <p>19th November 2021</p>
        <p>
          At SHAPE we appreciate everyone's feedback. In this release we have:
        </p>

        <ol>
          <li>Incorporated eNPS into the SHAPE Report</li>
          <li>Fixed minor bugs in the SHAPE Survey & SHAPE Report</li>
        </ol>
        <br />
        <br />

        {/* release 1.1.0 */}
        <h1>Release 1.1.0</h1>
        <p>22nd October, 2021</p>
        <p>
          Thank you to everyone for using SHAPE. With Release 1.1.0 being our
          first major release since launch, it’s fair to say your patience has
          paid off!
        </p>
        <p>Here are the highlights:</p>
        <ol>
          <li>
            Members of the same team can now use Your Network concurrently
          </li>
          <li>Survey 2.0 has been deployed. This means:</li>
        </ol>
        <ul>
          <li>Overall survey completion time has been reduced</li>
          <li>New questions have been added within the survey</li>
          <li>
            New designs have been added for questions in Physical Health,
            Psychological Health, and Work From Home (WFH) status question
          </li>
          <li>Minor bug fixes have been made</li>
        </ul>

        <p>We look forward to your feedback for continuous improvement.</p>

        <br />
        <br />

        {/* release 1.0.4 */}
        <h1>Release 1.0.4</h1>
        <p>8th October, 2021</p>
        <p>
          Thank you to all users for your patience. In this release, we have:
        </p>
        <ol>
          <li>
            Added time & date to intro waiver for SSA when starting the SHAPE
            survey
          </li>
          <li>Minor changes to question design in Client Onboarding</li>
          <li>Meta-tag text updated for the SHAPE website when sharing</li>
        </ol>
        <br />
        <br />

        {/* release 1.0.3 */}
        <h1>Release 1.0.3</h1>
        <p>10th September, 2021</p>
        <p>Thank you for your ongoing feedback. In this release, we have:</p>

        <ol>
          <li>
            Added compatibility for longer names in SHAPE Administrator, CEO,
            and Executive cards in Help centre and Company Information
          </li>
          <li>Added survey start and end date to SHAPE Kit Timeline view</li>
          <li>Fixed minor bugs in the report</li>
        </ol>
        <p>
          We've got more complex changes coming up in future releases. Keep
          sharing feedback to help us continue improving.
        </p>
        <br />
        <br />
        {/* release1.0.2 */}
        <h1>Release 1.0.2</h1>
        <p>27th August, 2021</p>
        <p>
          Thank you to all users who've shared feedback. In this release, we
          have:
        </p>

        <ol>
          <li>
            Started displaying survey deadline time according to client time
            zone
          </li>
          <li>
            Made minor UI refinements in Demographics and Physical Health
            sections of the survey
          </li>
          <li>
            Added a ‘Does not apply’ button for the site code question in
            Demographics
          </li>
          <li>Fixed minor bugs across survey and report</li>
        </ol>
        <p>
          We’re always listening. Keep sharing your feedback to help us continue
          to improve SHAPE.
        </p>
        <br />
        <br />

        <h1>Release 1.0.1</h1>
        <p>13th August, 2021</p>
        <p>
          Thank you to all early adopters of SHAPE. It's fantastic to see great
          performance and receive valuable feedback. In this release, we have:
        </p>

        <ol>
          <li>Implemented the offboarding function in the survey</li>
          <li>
            Added the ability to switch on the Company Score toggle for Question
            Level Breakdown for personas at client request
          </li>
          <li>
            Added the function of sending pilot reminder emails for
            non-onboarded pilot companies
          </li>
          <li>
            Restricted the addition of SHAPE Administrators and Executives with
            same email addresses during onboarding and of CEO in Executive Team
          </li>
          <li>Fixed minor bugs in Client Onboarding</li>
        </ol>
        <p>
          Keep an eye out for more improvements and new features in our upcoming
          releases.
        </p>
        <br />
        <br />
        <h1>Release 1.0</h1>
        <p>2nd July 2020</p>
        <p>
          We’re proud to launch our comprehensive survey with extensive results
          full of charting, statistical analysis, and threshold-based insights.
        </p>
        <p>
          This first release of SHAPE is a beta version, but a robust one
          nonetheless.
        </p>
        <ol>
          <li>
            Users can complete a comprehensive survey that covers 12 key topics
            impacting employee performance
          </li>
          <li>
            The survey ensures anonymity by not disclosing individual employees’
            responses to others, including managers and executives
          </li>
          <li>
            The product also offers instant access to a confidential,
            personalised report to all users upon submitting the survey
          </li>
          <li>
            After the survey deadline, users can view a more detailed report
            that provides data-powered analytics, best practices, and
            comparisons with teams and the rest of the company
          </li>
          <li>
            The reporting suite also allows a deeper dive into questions through
            Question Level Breakdown, and includes personalised features such as
            health metrics
          </li>
          <li>
            The menu provides access to participation resources (such as email
            templates), handbooks, and presentations for key figures in the
            company
          </li>
          <li>
            Self-improvement resources such as factsheets and infographics
            within the product provide additional insights for improving
            personal wellbeing and productivity
          </li>
          <li>
            SHAPE is currently offered in English, but we plan to add additional
            languages upon request and as we scale.
            <br />
            We’re continuing to add more features and improve performance as we
            progress.
          </li>
        </ol>
        <p>
          As continuous improvers, we’re all ears. Let us know if you have any
          suggestions or see something we missed.
        </p>
      </div>
    </Layout>
  )
}

export default Changes
